<template>
    <v-container v-if="$store.state.user">
    
        <!-- Page Title -->
        <v-row class="text-center mt-10">
            <v-col class="mb-4">
                <h1 class="display-2 font-weight-bold mb-3">
                Associations
                </h1>

                <!-- Button to add an association -->
                <v-btn elevation="2" outlined color="#4682b4" class="mt-5 mr-4"
                @click="$router.push({ path: '/associations/addNewAssociation' })"
                >Add New Association</v-btn>
            </v-col> 
        </v-row>

        <v-row v-if="!this.associations" class="justify-center mt-10">
            <h3>Loading...</h3>
        </v-row>

        <!-- Expandable Associations start -->
        <v-row v-if="this.associations" class="justify-center mb-10" >
            <v-expansion-panels popout>
                <v-expansion-panel v-for="(item, index) in this.associations.data.rows" :key="index">

                    <!-- Association name (expandable row header) -->
                    <v-expansion-panel-header>{{ item.name }}</v-expansion-panel-header>

                    <!-- Content within expandable row (when selected) -->
                    <v-expansion-panel-content>
                        Interest: {{item.interest}}%<br><br>
                        Late Fee: (Under Development)<br><br>
                        Notes: {{item.notes}} 
                        <p v-if="item.notes.length === 0"><i>No notes at this time.</i></p>
                        <br>

                        <!-- Buttons inside expandable rows -->

                        <!-- Edit button and corresponding modal -->
                        <v-btn elevation="2" outlined color="#4682b4" class="mt-5 mr-4"
                        @click="moveToPage(item.id, '/associations/associationsEdit')"
                        >Edit</v-btn>


                        <!-- Add Person Button -->
                        <v-btn elevation="2" outlined color="#4682b4" class="mt-5 mr-4"
                        @click="moveToPage(item.id, '/associations/peopleAdd')"
                        >Add Person</v-btn>

                        <!-- View People Button -->
                        <v-btn elevation="2" outlined color="#4682b4" class="mt-5 mr-4"
                        @click="moveToPage(item.id, '/associations/associationsView')"
                        >View People</v-btn>

                        <!-- View Archived People Button -->
                        <v-btn elevation="2" outlined color="#4682b4" class="mt-5 mr-4"
                        @click="moveToPage(item.id, '/associations/archivedByAssociation')"
                        >View Archived Accounts</v-btn>

                        <!-- Delete button and corresponding modal -->
                        <delete-modal :id="item.id" mode="association" 
                            v-on:rerender="removeFromExpansionPanels(item.id)" button_classes="mt-5 mr-4">
                        </delete-modal>

                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    </v-container>
</template>

<script>
    import AssociationsService from '@/services/AssociationsService'
    import DeleteModal from '../../components/DeleteModal.vue'
    export default {
        name: 'Associations',
        data () {
            return {

                // Array that holds value returned from API hit below
                associations: null,
                associationID: null,
                dialog: false,

                // Variables connected to Edit Form
                associationName: "",
                interest: null,
                notes: "",
            }
        },

        async mounted () {
            // Get all associations from back-end
            this.associations = await AssociationsService.getAllAssociations()
        },

        methods: {
            moveToPage: function (id, route) {
                this.$router.push({ path: `${route}/${id}`})
            },

            // When an entry in the backend database is deleted, this removes the item from associations[].
            removeFromExpansionPanels(itemID) {
                
                // First, find the index of the object in basicLedger[] that has the same id as itemID
                const result = this.associations.data.rows.findIndex(element => element.id == itemID)

                // Then, remove it from basicLedger[].
                this.associations.data.rows.splice(result, 1)

            }

        },

        components: {
            // Importing the DeleteModal component allows the use of it as <modal>
            DeleteModal
        }
    }
</script>

<style scoped>
    delete-modal {
        margin-top: 100px;
        margin-right: 20px;
    }
</style>